<template>
	<section class="promotions background-light main-content align-center">
		<div class="row row-header align-center">
			<h2 class="subtitle">{{ subtitle }}</h2>
			<h2>{{ header }}</h2>
			<div v-parse-links v-html="content" />
		</div>
		<div class="row promotions-row flex-row slide-row">
			<bc-carousel
				ref="roomsCarousel"
				class="room-slider"
				:mouse-drag="false"
				:breakpoints="breakpoints"
				:items-to-scroll="1"
				:wrap-around="true"
				snap-align="start"
				:model-value="0"
			>
				<template #addons>
					<bc-navigation class="slider-navigation">
						<template #next>
							<div class="slider-button slider-next">
								<font-awesome-icon icon="fa-light fa-chevron-circle-right" size="1x" />
							</div>
						</template>
						<template #prev>
							<div class="slider-button slider-prev">
								<font-awesome-icon icon="fa-light fa-chevron-circle-left" size="1x" />
							</div>
						</template>
					</bc-navigation>
				</template>
				<bc-slide v-for="room in items.slice(0, 5)" :key="room.ID" class="room-item slider-item active">
					<nuxt-link :to="localePath(`/${room.filename}`)" class="slick-item">
						<div class="slick-slide-content">
							<div class="title">
								<h3>{{ room.header }}</h3>
								<h3 v-if="room.subtitle" class="small">{{ room.subtitle }}</h3>
							</div>
							<span v-if="room.price" class="price">
								{{ room.price }}
							</span>
							<span v-if="room.button">
								{{ room.button }}
								<!-- <font-awesome-icon icon="fa-light fa-arrow-right-long" size="1x" /> -->
							</span>
						</div>
						<div class="image-holder">
							<picture>
								<source
									v-if="room.imageOverviewPortraitWebp"
									:srcset="room.imageOverviewPortraitWebp"
									type="image/webp"
								/>
								<source :srcset="room.imageOverviewPortrait" />
								<img
									loading="lazy"
									:src="room.imageOverviewPortrait"
									:alt="room.imageOverviewPortraitAlt"
								/>
							</picture>
						</div>
					</nuxt-link>
				</bc-slide>
			</bc-carousel>
		</div>
	</section>
</template>

<script setup>
defineProps({
	items: { type: Array, default: () => [] },
	subtitle: { type: String, default: '' },
	header: { type: String, default: '' },
	content: { type: String, default: '' },
	button: { type: String, default: '' },
});

const roomsCarousel = ref(null);

const breakpoints = {
	700: {
		itemsToShow: 2,
	},
	1080: {
		itemsToShow: 4,
	},
};
</script>

<style lang="scss" scoped>
.row-header {
	max-width: 980px;
}

.slide-row {
	width: 100%;
	padding: 0 20px;

	.carousel {
		width: 100%;
		padding: 0 50px;
	}
}

.slider-item {
	padding: 0 4px;
}

.image-holder {
	border: 8px solid #fff;
	overflow: hidden;
	height: 100%;
	width: 100%;
}

.slick-item {
	padding: 0 0 50px;
	width: 100%;
	overflow: hidden;

	img {
		transition: all 0.3s ease-in-out;
	}

	&:hover {
		img {
			transform: scale(1.06);
		}
	}

	h3 {
		font-size: $h4-font-size;
	}

	.price {
		display: inline-block;
		width: 100%;
		margin: -3px 0 10px;
	}
}

.slick-slide-content {
	background: $alternating-background-color;
	backdrop-filter: blur(12px);
	bottom: 0;
	padding: 20px;
	left: 20px;
	right: 20px;
	min-height: 105px;
	display: flex;
	flex-flow: column wrap;
	justify-content: flex-start;
	align-items: flex-start;

	h3 {
		font-size: 1.4em;

		&.small {
			font-size: calc($body-font-size - 4px);
			font-weight: 400;
			font-family: $body-font-family;
		}
	}

	.title {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: flex-end;
		gap: 5px;
		margin: 0 0 10px;

		h3 {
			margin: 0;

			&.small {
				margin: 0 0 3px;
			}
		}
	}
}

.slider-button {
	border: none;
	color: #fff;
	cursor: pointer;
	font-size: 40px;
	z-index: 2;
}

/* stylelint-disable selector-class-pattern */
:deep(.carousel__viewport) {
	width: 100%;
}

.carousel__slide a,
:deep(.carousel__slide) a {
	width: 100% !important;
}

:deep(.slider-navigation) {
	position: absolute;
	bottom: 0;
	left: 0;
	top: calc(50% - 20px);
	transform: unset;
	width: 40px;
	height: 40px;
	margin: 0;
	padding: 0;

	&.carousel__next {
		left: unset;
		right: 0;
	}
}
/* stylelint-enable selector-class-pattern */

@media (max-width: 680px) {
	/* stylelint-disable selector-class-pattern */
	:deep(.slider-navigation) {
		top: unset;
		bottom: 0;
	}
	/* stylelint-enable selector-class-pattern */

	.slide-row {
		width: 100%;
		padding: 0 20px;

		.carousel {
			padding: 0 0 60px;
		}
	}
}
</style>

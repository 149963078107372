<template>
	<div>
		<heading-image
			v-if="page.headingImages.length && page.headingImages[0].image"
			:heading-image="{
				image: page.headingImages[0].image,
				imageWebp: page.headingImages[0].imageWebp,
				imagePortrait: page.headingImages[0].imagePortrait,
				imagePortraitWebp: page.headingImages[0].imagePortraitWebp,
			}"
		>
			<template #heroContent>
				<div v-if="defaults[locale].homepage.headingTitle" class="hero-content">
					<span class="hero-title">
						{{ defaults[locale].homepage.headingTitle }}
					</span>
					<span class="hero-subtitle">
						{{ defaults[locale].homepage.headingSubTitle }}
					</span>
				</div>
			</template>
		</heading-image>

		<breadcrumbs
			v-if="page && page.pageID != 1"
			:breadcrumbs="page.breadcrumbs"
			:class="page.headingImages && page.headingImages.length ? '' : 'no-slider'"
			:home="defaults[locale].website.hotelName"
		/>

		<main-content
			:title="page.title || page.header"
			:subtitle="page.subtitle"
			:content="page.content"
			:booking-id="page.bookingID"
		/>

		<section class="main-content benefits">
			<div class="row">
				<div class="columns column4">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.sectionBenefitsSubtitle }}
					</h2>
					<h2>
						{{ defaults[locale].homepage.sectionBenefitsTitle }}
					</h2>
					<ul>
						<li v-for="(benefit, key) in defaults[locale].homepage.bookingBenefits" :key="key">
							<span>{{ benefit }}</span>
						</li>
					</ul>

					<book-button class="button button-cta reserve-now">
						{{ $t('bookNow') }}
					</book-button>
					<div v-parse-links v-html="defaults[locale].homepage.sectionBenefitsContent" />
				</div>
			</div>
			<picture>
				<source
					v-if="defaults[locale].homepage.sectionBenefitsImageWebp"
					:srcset="defaults[locale].homepage.sectionBenefitsImageWebp"
					type="image/webp"
				/>
				<source :srcset="defaults[locale].homepage.sectionBenefitsImage" />
				<img
					class="fade-image"
					:src="defaults[locale].homepage.sectionBenefitsImage"
					:alt="defaults[locale].homepage.sectionBenefitsImageAlt"
					loading="lazy"
				/>
			</picture>
		</section>

		<promoblocks
			v-if="defaults[locale].homepage.sectionPromotionTitle"
			:header="defaults[locale].homepage.sectionPromotionTitle"
			:subtitle="defaults[locale].homepage.sectionPromotionSubtitle"
			:promoblocks="promoblocksData"
			:amount="4"
		/>

		<rooms-overview
			:items="roomsData"
			:header="defaults[locale].rooms.sectionRoomsTitle"
			:subtitle="defaults[locale].rooms.subtitle"
			:content="defaults[locale].rooms.sectionRoomsContent"
			:button="defaults[locale].rooms.more"
		/>

		<contentblocks
			v-if="page && page.contentblocks && page.contentblocks.length"
			:contentblocks="page.contentblocks"
			:content-blocks-content="page.sectionContentblocksContent"
			:content-blocks-subtitle="page.sectionContentblocksTitle"
		/>

		<frequently-asked-questions
			v-if="page && page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>
		<pre-footer v-if="page && page.footerSection.length" :footer-section="page.footerSection[0]" />
		<newsletter
			v-if="page && page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>

		<news-overview :news="news" />
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});

const { data: news } = await useWebsiteFetch('news', {
	query: { language: locale.value },
	key: `${locale.value}/news`,
});

const { data: roomsData } = await useWebsiteFetch('rooms', {
	query: { language: locale.value },
	key: `${locale.value}/rooms`,
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	bottom: 27%;
	left: 0;
	right: 0;
	z-index: 3;
	text-align: center;
	padding: 0 20px;
	color: #fff;
	animation: fade-from-bottom 1.2s ease;
}

.hero-content span {
	color: #fff;
	font-weight: 400;
	font-size: 50px;
	letter-spacing: 1px;
	width: 100%;
	font-family: $heading-font-family;
	display: inline-block;
	margin: 0 0 15px;
	text-transform: uppercase;
}

.hero-content .hero-subtitle {
	font-size: 20px;
	font-weight: 300;
	font-family: $body-font-family;
	text-transform: none;
}

.benefits .fade-image {
	opacity: 1;
	width: 100%;
	left: auto;
	top: 0;
	bottom: 0;
	height: calc(100%);
}

.benefits {
	position: relative;
	padding: 50px 0;

	h2 {
		font-size: 26px;

		&.subtitle {
			font-size: 20px;
			margin: 0 0 30px;
		}
	}

	p,
	:deep(p) {
		font-size: calc($body-font-size - 3px);
		color: #000;
		margin: 20px 0 0;
	}
}

.benefits .row {
	z-index: 2;
	position: relative;
}

.benefits .column4 {
	background: rgba(255 255 255 / 90%);
	box-shadow: 0 18px 18px rgba(0 0 0 / 25%);
	backdrop-filter: blur(8px);
	padding: 45px;

	h2,
	h3 {
		color: #034638;

		&.subtitle {
			color: #034638;
		}
	}

	ul,
	ol,
	p {
		color: #131313;
	}
}

.benefits ul {
	list-style: none;
	font-size: 18px;
	margin: 30px 0;
}

.benefits li {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	margin: 15px 0;

	span {
		width: calc(100% - 70px);
	}
}

.benefits li::before {
	content: '';
	background: url('~/assets/images/icon-check.png') no-repeat center center;
	background-size: 40px;
	height: 40px;
	width: 40px;
	display: block;
	margin: 0 20px 0 0;
}

@media (max-width: 1180px) {
	.benefits {
		.column4 {
			width: 50%;
		}
	}

	.hero-content {
		bottom: 22%;
	}
}

@media (max-width: 920px) {
	.hero-content {
		bottom: 20%;

		.hero-title {
			font-size: 46px;
		}

		.hero-subtitle {
			font-size: 19px;
		}
	}

	.benefits {
		padding: 110px 20px;

		.row {
			width: 93%;
		}

		li {
			margin: 10px 0;
		}

		.column4 {
			width: 100%;
			padding: 30px;
		}

		.fade-image {
			position: absolute !important;
			inset: 0 !important;
			height: 100% !important;
			width: 100% !important;
			margin: 0 !important;
			z-index: 1;
		}
	}
}

@media (max-width: 680px) {
	.hero-content {
		bottom: 18%;

		.hero-title {
			font-size: 36px;
		}

		.hero-subtitle {
			font-size: 18px;
		}
	}

	.benefits {
		.column4 {
			padding: 35px 20px 35px 16px;
		}

		h2 {
			font-size: 20px;

			&.subtitle {
				font-size: 16px;
				margin: 0 0 30px;
			}
		}

		ul {
			list-style: none;
			font-size: 15px;
			margin: 20px 0;
		}

		li {
			span {
				width: calc(100% - 25px);
			}
		}

		li::before {
			width: 22px;
			height: 22px;
			background-size: 22px;
			margin: 0 6px 0 0;
		}
	}
}

@media (max-width: 520px) {
	.hero-content {
		bottom: 16%;

		.hero-title {
			font-size: 30px;
		}
	}
}

@media (max-width: 420px) {
	.hero-content .hero-title {
		font-size: 27px;
	}

	.hero-content .hero-subtitle {
		font-size: 17px;
	}
}
</style>
